import React from "react"
// import Footer from "./footer"
import "./layout.css"

const BaseLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-1">{children}</main>
      {/* <Footer /> */}
    </div>
  )
}

export default BaseLayout
