import React from "react"

export const Logo = () => (
  <svg viewBox="0 0 1 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.527371 0.11489C0.625 0.0172592 0.783292 0.0172592 0.880921 0.11489C0.978554 0.212521 0.978554 0.370813 0.880921 0.468442L0.468442 0.880921C0.370812 0.978554 0.212521 0.978554 0.11489 0.880921C0.0172588 0.783292 0.0172587 0.625 0.11489 0.527371L0.527371 0.11489ZM0.821996 0.409518L0.645221 0.586296L0.409518 0.350592L0.586296 0.173816C0.651383 0.108729 0.756908 0.108729 0.821996 0.173816C0.887083 0.238903 0.887083 0.344431 0.821996 0.409518Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0.121094"
        y1="0.875"
        x2="1.45703"
        y2="-0.488281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#99F6E4" />
        <stop offset="1" stop-color="#22D3EE" />
      </linearGradient>
    </defs>
  </svg>
)
